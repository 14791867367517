$(initializeSearchFunctions);
document.addEventListener('turbo:render', initializeSearchFunctions);

function initializeSearchFunctions() {
    let $searchInput = $("#searchInput"),
        $formSearch = $searchInput.closest('.main-search'),
        $allResultsButton = $('.show-all-search-results'),
        $searchInputClear = $formSearch.find('.clear_search_input'),
        $previewSearch = $formSearch.find('.search-preview-results'),
        $resultSearch = $previewSearch.find('.wrap_results'),
        searchValue = $searchInput.val(),
        timerIdContainer = {"content": null},
        spinOptionsSearch = {
            lines: 10,
            length: 0,
            width: 9,
            radius: 15,
            scale: 0.7,
            corners: 1,
            color: '#E32B2D',
            opacity: 0.2,
            rotate: 0,
            direction: 1,
            speed: 1,
            trail: 60,
            fps: 20,
            zIndex: 100,
            className: 'spinner',
            top: '50%',
            left: '50%',
            shadow: false,
            hwaccel: false,
            position: 'absolute'
        };

    const searchInputFocus = 500,
        searchTimeout = 200,
        debounceTime = 500;

    let hideLoadingSearch = function() {
        $previewSearch.find('.loading').empty();
        $previewSearch.removeClass('waiting-for-request');
    };
    let showLoadingSearch = function() {
        hideLoadingSearch();
        // noinspection JSUnresolvedFunction
        $previewSearch.find('.loading').append($(new Spinner(spinOptionsSearch).spin().el));
        $previewSearch.addClass('waiting-for-request');
    };
    let openModelSearch = function (modelSlug) {
        let url = CONFIG.URL.urlGetProducts.replace('{modelSlug}', modelSlug);

        $.post(url, {}, function (response) {
            if (response && response.data) {
                window.drawProducts(modelSlug, response);
            } else {
                message.error(CONFIG.LANG.unknown_error);
            }
            hideLoadingSearch();
        }, 'json').fail(function (response) {
            showResponseError(response);
            hideLoadingSearch();
        });
    };

    let searchQueryNumber = 0;

    let quickSearch = function () {
        let value = $searchInput.val();

        if (value !== searchValue) {
            if (timerIdContainer.content !== null) {
                clearTimeout(timerIdContainer.content);
            }

            $allResultsButton.addClass('hidden');
            $previewSearch.removeClass('hidden');

            if (value === '') {
                $searchInputClear.addClass('hidden');
            } else {
                $searchInputClear.removeClass('hidden');
            }

            timerIdContainer.content = setTimeout(function () {
                searchQueryNumber++;
                const currentQueryNumber = searchQueryNumber;

                $.post(
                    $searchInput.data('quick-search-url'),
                    {"search": value},
                    function (response) {
                        // есть более поздний запрос, игнорируем текущий
                        if (currentQueryNumber < searchQueryNumber) {
                            return;
                        }
                        // noinspection JSUnresolvedVariable
                        if (+(response.resultsCount) > 0) {
                            $allResultsButton.removeClass('hidden')
                        } else {
                            $allResultsButton.addClass('hidden')
                        }
                        const html = response.html.toString().trim();
                        html === '' ?
                            $previewSearch.addClass('hidden') :
                            $previewSearch.removeClass('hidden');
                        searchValue = value;
                        $resultSearch.html(html);
                        $('.search-preview-model').on('click', function () {
                            if ($(this).prop("tagName") !== 'A') {
                                showLoadingSearch();
                                openModelSearch($(this).data('model-slug'));
                            }
                        });

                        $('.suggest').on('click', function () {
                            $searchInput.val(this.innerText);
                            quickSearchWrapDebounce();
                        });
                    }
                );
            }, searchTimeout);
        }
    };
    let quickSearchWrapDebounce = $.debounce(quickSearch, debounceTime);

    if ($formSearch.parent('.top_search').length === 0) {
        $("#showSearchButton").on('click', function () {
            $formSearch.toggleClass('active');
            $searchInputClear.addClass('hidden');
            $previewSearch.addClass('hidden');
            $resultSearch.html('');
            hideLoadingSearch();
            setTimeout(function () {
                $searchInput.focus();
            }, searchInputFocus);
            if ($formSearch.hasClass('active') && $searchInput.val() !== '') {
                // noinspection ReuseOfLocalVariableJS
                searchValue = '';
                quickSearch();
            }
        });
    }
    $searchInput.on('blur', function () {
        if ($(this).val() === '') {
            if ($formSearch.parent('.top_search').length === 0) {
                $formSearch.removeClass('active');
            }
            $previewSearch.addClass('hidden');
            $resultSearch.html('');
            hideLoadingSearch();
        }
    });
    $searchInput.on('keyup', function () {
        quickSearchWrapDebounce();
    });
    $searchInputClear.on('click', function () {
        searchValue = '';
        $searchInput.val('');
        $searchInput.focus();
        $searchInputClear.addClass('hidden');
        $previewSearch.addClass('hidden');
        $resultSearch.html('');
        hideLoadingSearch();
    });
    $allResultsButton.on('click', function () {
        $formSearch.trigger('submit');
    });
}
